import { get, HttpResponse } from '@/api/Api';
import { api_url } from '@/api/thg/api';
import { THGGlobal } from '@/interfaces/thg/THGlobals';
import { TokenType } from '@/enums/TokenType';

/**
 * Retrieve the THG Globals
 *
 * @return globals response
 */
export async function getGlobals(): Promise<HttpResponse<THGGlobal>> {
    return await get<THGGlobal>(`${api_url()}/globals`, TokenType.THG_API_KEY);
}
